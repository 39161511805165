import { render, staticRenderFns } from "./SearchHeader.vue?vue&type=template&id=511657b9&scoped=true"
import script from "./SearchHeader.vue?vue&type=script&lang=js"
export * from "./SearchHeader.vue?vue&type=script&lang=js"
import style0 from "./SearchHeader.vue?vue&type=style&index=0&id=511657b9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "511657b9",
  null
  
)

export default component.exports