<template>
  <div class="SearchHeader">
    <h3 v-if="chat" class="SearchHeader__title">
      {{ $t('chat-search-page_header_title', { chatName: chat.name }) }}
    </h3>
    <LLSearchBar
      v-model="localSearchValue"
      :placeholder="$t('search-page_input-placeholder')"
      class="SearchHeader__input"
      @search="search"
    ></LLSearchBar>
    <!-- <div v-if="localSearchValue.length < 3 && localSearchValue.length > 0" class="text-error-01 text-xs mt-2">
      Search keywords should be at least 3 characters long
    </div> -->
    <LLTabs v-if="showTabs" class="SearchHeader__tabs" no-border @change="onTabChange">
      <LLTab
        :name="SearchCategories.RESPONSES"
        :label="$t('study-search-page_tabs_responses')"
        :active="localSearchCategory === SearchCategories.RESPONSES"
        :selected="localSearchCategory === SearchCategories.RESPONSES"
      ></LLTab>
    </LLTabs>
  </div>
</template>

<script>
import { SearchCategories } from '@/common/enums'
import LLSearchBar from '@/components/common/LLSearchBar'
import { StudyModel } from '@/models/study'
import LLTabs from '@/components/common/tabs/LLTabs'
import LLTab from '@/components/common/tabs/LLTab'
import { ForumChatModel } from '@/models/forum/forumChat'
export default {
  name: 'SearchHeader',
  components: { LLTab, LLTabs, LLSearchBar },
  props: {
    study: { type: [StudyModel, null], default: null },
    chat: { type: [ForumChatModel, null], default: null },
    searchCategory: { type: Number, default: SearchCategories.RESPONSES },
    searchValue: { type: String, default: null }
  },
  data() {
    return {
      showTabs: false,
      localSearchCategory: '',
      localSearchValue: ''
    }
  },
  computed: {
    SearchCategories() {
      return SearchCategories
    }
  },
  watch: {
    searchCategory: {
      immediate: true,
      handler() {
        this.localSearchCategory = this.searchCategory
      }
    },
    searchValue: {
      immediate: true,
      handler() {
        this.localSearchValue = this.searchValue
      }
    }
  },
  methods: {
    search() {
      if (this.localSearchValue.length < 3) {
        if (this.localSearchValue.length !== 0) {
          this.$toast.error(this.$t('search-page_keyword_too_short'))
        }
        return
      }
      this.$emit('update:searchValue', this.localSearchValue)
      this.$emit('search')
    },
    onTabChange(name) {
      this.localSearchCategory = name
      this.$emit('update:searchCategory', this.localSearchCategory) /*
      this.$emit('search') */
    }
  }
}
</script>

<style lang="scss" scoped>
.SearchHeader {
  &__title {
  }
  &__input {
    width: 100% !important;
    @apply mt-7;
  }
  &__tabs {
    @apply mt-7;
    height: 46px;
  }
}
</style>
